// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const client_id = location.hostname + '-yutsys-backoffice-frontend';
// const host = 'http://58.97.6.27';
const server = {
  local: location.protocol + '//localhost',
  public: location.protocol + '//192.168.110.2',
  public2: 'https://mbackoffice.yamatothai.com/backend/api',
};

export const environment = {
  production: false,
  apiEndpoint: server.local + ':51503',

  // version: env.npm_package_version + '-dev',
  // authUrl: server.local + ':51001',
  authUrl: 'https://login.yamatothai.com',
  authEndPoint: '/connect/authorize?',
  // serverUrl: server.public + ':52150',
  // serverUrl: server.local + ':50503',
  serverUrl: server.public2,
  project: server.public + ':52105/api',
  // project: server.public + ':50105',
  storage: server.public + ':50003/api',
  // user:server.local +':50006',
  user: server.public + ':52006/api',
  defaultLanguage: 'en-US',
  supportedLanguages: [
    'en-US',
    'fr-FR'
  ],
  identityConfigs: {
    identityUrl: 'https://login.yamatothai.com',
    // identityUrl: 'http://192.168.110.2:52001',
    projectId: 'f1354fef-6407-423c-b6e0-58a3f0f08ad0',
    customerId: '',
    authorizeEndpoint: '/connect/authorize?',
    queryParams: {
      response_type: encodeURI('id_token token'),
      client_id: encodeURI(client_id),
      redirect_uri: encodeURI(location.origin),
      post_logout_redirect_uri: encodeURI(location.origin),
      scope: encodeURI('openid profile wmsreceiving backoffice'),
      nonce: '',
      state: '',
    },
    authParam: {
      client_id: encodeURI(client_id),
      client_secret: 'wms1234',
      scope: 'openid profile backoffice wmsreceiving',
      grant_type: 'password',
    },
  },
  encryptConfigs: {
    enable: true,
    pwd: 'OCYzM2xpZkxJSDNqZmcqRWY=',
  },
};

