import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { TokenHelperService } from './token-helper.service';
import { HttpHelper } from '../helpers/http.helper';
import { Router } from '@angular/router';
import { StorageService } from '../service/storage.service';
import jwt_decode from "jwt-decode";
const log = console;

@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  private _isAuthorized = new BehaviorSubject<boolean>(false);
  public isAuthorized: boolean;
  public userData: any;

  constructor(
    private storage: StorageService,
    private tokenHelper: TokenHelperService,
    private router: Router
  ) {
  }


  public getIsAuthorized(): Observable<boolean> {
    return this._isAuthorized.asObservable();
  }

  public setIsAuthorized(isAuthorized: boolean): void {
    this.isAuthorized = isAuthorized;
    this._isAuthorized.next(isAuthorized);
  }

  public Authorize() {
  }

  private getQueryParams() { }


  private splitIdToken(): any {
    const hash = window.location.hash.substr(1);
    const result: any = hash.split('&').reduce(function (_result: any, _item: string) {
      const parts = _item.split('=');
      _result[parts[0]] = parts[1];
      return _result;
    }, {});
    return result;
  }

  public AuthorizedCallback(): Observable<boolean> {
    const authorizedCallbackObservable: Observable<boolean> = new Observable(observer => {
    });
    return authorizedCallbackObservable;
  }

  public ResetAuthorizationData() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('IsAuthorized');
    this.setIsAuthorized(false);

  }

  private getDataFromToken(token: any) {

    let data = {};
    if (typeof token !== 'undefined') {
      const encoded = token.split('.')[1];
      data = JSON.parse(this.tokenHelper.urlBase64Decode(encoded));
    }

    return data;
  }



  public SetAuthorizationData(access_token: any, id_token: any): Observable<boolean> {
    const setAuthorizationDataObservable: Observable<boolean> = new Observable(observer => {
    });
    return setAuthorizationDataObservable;
  }

  public HandleError(error: any) {
    console.log(error);
    if (error.status === 403) {
    } else if (error.status === 401) {
      this.Logoff();
    }
  }

  // public getUserData(): Observable<string[]> {
  //   return '';
  // }

  getUserDataStorage(): any {
    const rs = localStorage.getItem('access_token');
    
    return jwt_decode(rs);;
    // return this.getDataFromToken(rs);
  }

  public getUserEmail(): string {
    return this.userData.email;
  }

  public GetToken(): any {
    return this.storage.retrieve('access_token');
  }

  public Logoff() {
    this.ResetAuthorizationData();
    this.router.navigate(['/login']);
  }


}


