import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'itsupport/request-manage',
    loadChildren: () => import('./pages/support/request-manage/request-manage.module').then(m => m.RequestManagePageModule)
  }, {
    path: 'interface',
    loadChildren: () => import('./pages/line-interface/line-interface1/line-interface1.module').then(m => m.LineInterface1Module)
  }
  , {
    path: 'auth',
    loadChildren: () => import('./pages/line-interface/line-interface2/line-interface2.module').then(m => m.LineInterface2Module)
  }, {
    path: 'account-register',
    loadChildren: () => import('./pages/account/account-register/account-register.module').then(m => m.AccountRegisterModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
