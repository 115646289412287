import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';
import { LoaderService } from '../service/loader.service';

@Injectable({
    providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
    constructor(
        private loaderService: LoaderService,
        public loadingController: LoadingController
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.showLoader();
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.onEnd();
            }
        },
            (err: any) => {
                this.onEnd();
            }));
    }
    private onEnd(): void {
        this.hideLoader();
    }
    private showLoader(): void {
        this.loaderService.show();
        // this.presentLoading();
    }
    private hideLoader(): void {
        this.loaderService.hide();
        // this.didDismissLoading();
    }


    async presentLoading() {
        const loading = await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: 'Please wait...',
            // duration: 2000
        });
        await loading.present();

        // const { role, data } = await loading.onDidDismiss();
    }

    didDismissLoading() {
        this.loadingController.dismiss();
    }
}
