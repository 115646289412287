import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IdentityService } from '../authentication/identity.service';
import { Logger } from '../service/logger.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private identityService: IdentityService) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      log.error('Request error', response);
    }
    if (response instanceof HttpErrorResponse) {
      switch (response.status) {
        case 401:
          this.identityService.HandleError(response);
          break;
        case 403:
          this.identityService.HandleError(response);
          break;
        case 500:
          const errorMessage = (response.error.ResponseException ?
            response.error.ResponseException.ExceptionMessage
            : response.error.Message);
          // Swal({
          //   title: 'Error!',
          //   text: errorMessage,
          //   type: 'error',
          //   confirmButtonText: 'Close'
          // });

          break;
        default:
          break;
      }
    }
    throw response;
  }

}
